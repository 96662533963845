import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import {Button, Empty} from "antd";

const NotFoundPage = () => (
  <Container defKey="-1">
    <SEO title="404: Not found" />
    <Empty description={"404 not found"}>Diese Seite existiert nicht.</Empty>
  </Container>
)

export default NotFoundPage
